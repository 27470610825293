/**
 * @function animations()
 *
 * Runs the animations across the entire site
 */

export default () => {
  const animationClass = "-animate";
  const animationSelector = "[data-animate]";

  const animate = element => element.classList.add(animationClass);
  const isAnimated = element => element.classList.contains(animationClass);

  // Only allow elements that have not already been animated
  const elements = [].filter.call(
    document.querySelectorAll(animationSelector),
    element => !isAnimated(element)
  );

  // Kick-off IntersectionObserver implementation
  let options = {
    threshold: .25
  };

  let callback = (elements, observer) => {
    elements.forEach(element => {
      if (element.isIntersecting) {
        animate(element.target);

        observer.unobserve(element.target);
      }
    });
  };

  let observer = new IntersectionObserver(callback, options);

  elements.forEach(element => {
    observer.observe(element);
  });
};
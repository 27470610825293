import debounce from './debounce';

export default () => {
  // Only run on mobile
  if (window.innerWidth > 767) return;
  
  // When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar
  let prevScrollPos = window.pageYOffset;
  const nav = document.querySelector(".header");

  window.onscroll = debounce(function() {
    const currentScrollPos = window.pageYOffset;

    // When the page is in its initial position
    if (window.pageYOffset > 50) {
      nav.classList.remove('-top');
    } else {
      nav.classList.add('-top');
    }

    // We need to handle three conditions to show/hide the global header when scrolling:
    //  1. If the previous scroll position is greater than the current scroll position
    //  2. If the current scroll position is zero (i.e., we are at the top of the page)
    //  3. If the current scroll position is less than zero (i.e., we have scrolled above the document, which Safari allows)
    if ((prevScrollPos > currentScrollPos) || (currentScrollPos === 0) || (currentScrollPos < 0)) {
      nav.classList.remove('-hidden');
    } else {
      nav.classList.add('-hidden');
    }

    prevScrollPos = currentScrollPos;
  });
}

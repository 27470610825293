export default () => {
  const portfolioGrids = document.querySelectorAll('.portfolio-grid');
  if (!portfolioGrids || portfolioGrids.length === 0) { return; }
  
  portfolioGrids.forEach(grid => {
    // Keeping track of grid items using incrementer
    // Necessary for CSS animations
    let j = 1;

    // Loop over grid items for each grid
    for (i = grid.children.length; i >= 0; i--) {
      // Retrieve random grid item and add inline CSS for animations
      let gridItem = grid.children[Math.random() * i | 0];
      gridItem.setAttribute('style', `--delay: calc(${j} * 30ms)`)
      
      // Append grid item to grid
      grid.appendChild(gridItem);

      // Increment for cascading animations
      j++;
    }
  });
}
/**
 * @function contact()
 *
 * Contains functionality for contact form
 */

export default () => {
  const form = document.querySelector('.contact-form');
  const success = document.querySelector('.contact-success');

  if (window.location.hash == "#success") { 
    form.classList.add('-hidden');
    success.classList.remove('-hidden');
  }
};
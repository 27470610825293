import animations from './modules/animations';
import contact from './modules/contact';
import lazyLoading from './modules/lazyLoading';
import mobileNavigation from './modules/mobileNavigation';
import carousel from './modules/carousel';
import showHideHeader from './modules/showHideHeader';
import shufflePortfolioCompanies from './modules/shufflePortfolioCompanies';

// Initialize
window.addEventListener('load', () => {
  animations();
  contact();
  mobileNavigation();
  lazyLoading();
  carousel();
  showHideHeader();
  shufflePortfolioCompanies();
});

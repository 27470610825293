// Take a string that contains a number
// The first number found is returned
const parseNumberInString = string => {
  const match = string.match(/[-.0-9]+/);

  return match ? parseInt(match[0]) : 0
};

export default () => {
  // Pick up all carousels available
  const carousels = document.querySelectorAll('.carousel');

  carousels.forEach(carousel => {
    const carouselView = carousel.querySelector('.carousel-view');
    const carouselItems = carousel.querySelectorAll('.carousel-item');
    const activeCarouselItem = carousel.querySelector('.carousel-item.-active');

    // TODO: use this function for click handlers
    function moveCarousel(destinationItem, direction, currentItem) {
      const destinationItemWidth = destinationItem.clientWidth + parseNumberInString(window.getComputedStyle(destinationItem).getPropertyValue('margin-right'));
      const directionMultiplier = (direction === "previous") ? 1 : -1;
      const scrollBy = (destinationItemWidth * directionMultiplier) + parseNumberInString(carouselView.style.transform) + 'px';
      carouselView.style.transform = `translateX(${ scrollBy })`;
      currentItem.classList.remove('-active');
      destinationItem.classList.add('-active');
    }

    // This sets the carousel to the second item initially so that there are images on both sides 
    // when the user first interacts with the carousel.
    moveCarousel(carouselItems[1], 'next', carouselItems[0]);

    // Keep the height static so sections below the carousel
    // don't start jumping when switching slides
    carousel.style.height = activeCarouselItem.clientHeight + 'px';

    // Always start at the beginning
    carouselView.scrollLeft = 0;

    carouselItems.forEach(carouselItem => {
      // We listen for clicks on each carousel item
      carouselItem.addEventListener('click', function(e) {
        const thisIndex = this.dataset.index;
        const currentItem = carousel.querySelector('.carousel-item.-active');
        const currentIndex = parseInt(currentItem.dataset.index);

        // We get the width of the item plus the right margin
        const selectedItemWidth = this.clientWidth + parseNumberInString(window.getComputedStyle(this).getPropertyValue('margin-right'));

        // Show previous
        // If the clicked item is on the left side (has a smaller index than the
        // currently active item) them move the carousel's inner container to the right
        if (thisIndex < currentIndex) {
          const scrollBy = selectedItemWidth + parseNumberInString(carouselView.style.transform) + 'px';

          carouselView.style.transform = `translateX(${ scrollBy })`;
          currentItem.classList.remove('-active');
          this.classList.add('-active');
        }

        // Show next
        // If the clicked item is on the right side (has a larger index than the
        // currently active item) them move the carousel's inner container to the left
        if (thisIndex > currentIndex) {
          const scrollBy = (selectedItemWidth * -1) + parseNumberInString(carouselView.style.transform) + 'px';

          carouselView.style.transform = `translateX(${ scrollBy })`;
          currentItem.classList.remove('-active');
          this.classList.add('-active');
        }
      });
    });

    // Go previous or next depending on swipe events
    const sensibility = 100;
    let touchStartX = 0;
    let touchEndX = 0;

    carousel.addEventListener('touchstart', function(event) {
        touchStartX = event.changedTouches[0].screenX;
    }, false);

    carousel.addEventListener('touchend', function(event) {
        touchEndX = event.changedTouches[0].screenX;

        const currentIndicator = carousel.querySelector('.carousel-indicators-item.-active');
        const currentIndicatorIndex = parseInt(currentIndicator.dataset.index)
        const currentSlide = carousel.querySelector(`.carousel-item[data-index="${ currentIndicatorIndex }"]`)

        const previousIndicator = carousel.querySelector(`.carousel-indicators-item[data-index="${ currentIndicatorIndex - 1 }"]`);
        const nextIndicator = carousel.querySelector(`.carousel-indicators-item[data-index="${ currentIndicatorIndex + 1 }"]`);


        // Show previous
        if (touchEndX > touchStartX - sensibility) {
          touchStartX = 0;

          carouselView.scrollBy({
            left: currentSlide.clientWidth * -1,
            behavior: 'smooth'
          });

          if (!previousIndicator) return;

          previousIndicator.classList.add('-active');
          currentIndicator.classList.remove('-active');
        }

        // Show next
        if (touchEndX < touchStartX + sensibility) {
          touchEndX = 0;

          carouselView.scrollBy({
            left: currentSlide.clientWidth,
            behavior: 'smooth'
          });

          if (!nextIndicator) return;

          nextIndicator.classList.add('-active');
          currentIndicator.classList.remove('-active');
        }
    }, false);
  });
}
